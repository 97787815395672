<template>

    <div class="box relative">
        <loader :loading="loading" />
        <div class="px-4 py-1 box-title flex justify-between">
            <span class="text-font-gray font-semibold text-sm">Monthly content activity</span>
            <span class="text-font-gray font-semibold text-sm">{{ $moment().format('MMMM, YYYY') }}</span>
        </div>
        <div class="py-2 flex">
            <div class="w-1/3 overflow-hidden text-center" v-for="(network, key) in networks" :key="key">
                <i class="mdi text-2xl text-font-gray" :class="'mdi-' + key"></i>
                <div class="mt-2">
                    <span class="mt-2 text-2xl font-bold"
                        :style="'color:' + colorBySocialNetwork(key)">
                        {{ network.postsCount | reduceBigNumbers }}
                    </span>
                </div>
                <div class="mt-2">
                    <p class="text-sm text-font-gray font-bold">
                        <span v-if="network.diff > 0">+</span>
                        <span v-else-if="network.diff < 0"></span>
                        {{ network.diff }}
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import { state } from '@/store';

    export default {
        name: "SocialMedia",
        data() {
            return {
                networks: [],
                loading: false
            }
        },
        methods: {
            colorBySocialNetwork(social) {
                return this.$colorSocialNetwork(social)
            },
            async loadNetworks() {
                this.loading = true;
                const { data } = await this.axios.get(process.env.VUE_APP_SOCIAL_URL + 'social/posts-count')
                    .finally(() => this.loading = false);
                this.networks = data;
            }
        },
        computed: {
            params() { return state.globalParams },
            imageRoute() { return this.$imageRoute }
        },
        watch: {
            params() { this.loadNetworks() }
        },
        created() { this.loadNetworks() }
    }
</script>